<template lang="pug">
  .simple-view.is-flex.lazy(data-lazy-function="loaded", :class="item.class")
    .item-example.lazy(data-lazy-function="loaded", :class="[`items-${item.class}`, this.itemHover]")
    v-link.sub-menu-item(@click.native="$emit('collapse')", :to="i.to", :type="i.type", @mouseover.native="onHoverIn(i.itemType)", v-for="i in item.items", :key="i.id") {{ i.text }}
</template>

<script>
export default {
  name: 'SimpleComponent',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    itemHover: ''
  }),

  methods: {
    onHoverIn(itemType) {
      this.itemHover = `${itemType}-item`
    },

    onHoverOut() {
      this.itemHover = 'items-jewelries'
    }
  }
}
</script>

<style lang="sass" scoped>
.simple-view
  position: relative
  min-height: 350px
  padding: 45px
  flex-direction: column
  font-size: 18px
  font-weight: 700
  z-index: 1
  background-color: #fff
  border-top: 1px solid $border-color
  @include box-shadow(0 4px 5px -3px, rgba(0,0,0,0.2))
  +touch
    box-shadow: unset
    padding: 20px
  a
    z-index: 1
  .item-example
    //transition: background-image 0.2s ease-in-out
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
  .sub-menu-item
    color: $default-font-color
    &:not(:last-child)
      margin-bottom: 20px
</style>
